import React from "react";
import Select from "react-select";
import { customStyles } from "../constants/customStyles";
import { languageOptions } from "../constants/languageOptions";
const LanguagesDropdown = ({ onSelectChange }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#1f2937',
      borderColor: state.isFocused ? '#3b82f6' : '#374151',
      borderWidth: '1px',
      boxShadow: state.isFocused ? '0 0 0 1px #3b82f6' : 'none',
      '&:hover': {
        borderColor: '#3b82f6'
      },
      borderRadius: '0.5rem',
      padding: '2px',
      minWidth: '200px'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1f2937',
      border: '1px solid #374151',
      borderRadius: '0.5rem',
      marginTop: '4px',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3b82f6' : state.isFocused ? '#374151' : 'transparent',
      color: state.isSelected ? 'white' : '#e5e7eb',
      cursor: 'pointer',
      '&:active': {
        backgroundColor: '#3b82f6'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#e5e7eb'
    }),
    input: (provided) => ({
      ...provided,
      color: '#e5e7eb'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9ca3af'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#6b7280',
      '&:hover': {
        color: '#9ca3af'
      }
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#6b7280',
      '&:hover': {
        color: '#9ca3af'
      }
    })
  };

  return (
    <div className="relative">
      <Select
        placeholder="Select Language"
        options={languageOptions}
        styles={customStyles}
        defaultValue={languageOptions[0]}
        onChange={(selectedOption) => onSelectChange(selectedOption)}
        className="font-medium"
        isSearchable={true}
      />
    </div>
  );
};

export default LanguagesDropdown;