import React, { useState } from 'react';
import { Copy, X, Check, Maximize2, Minimize2, Terminal } from 'lucide-react';

const CustomInput = ({ customInput = "", setCustomInput }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const inputValue = customInput || "";

  const handleCopy = async () => {
    await navigator.clipboard.writeText(inputValue);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setCustomInput(text);
    } catch (err) {
      console.error('Failed to paste:', err);
    }
  };

  return (
    <div className={`transition-all duration-300 ease-in-out ${
      isExpanded ? 'fixed inset-4 z-50 bg-gray-900 rounded-xl shadow-2xl border border-gray-700' : 'relative'
    }`}>
      {/* Header */}
      <div className="px-4 py-3 flex items-center justify-between border-b border-gray-800 
        bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 shadow-lg">
        <div className="flex items-center space-x-2">
          <Terminal className="w-5 h-5 text-purple-400" />
          <h2 className="text-lg font-semibold text-gray-200">Custom Input</h2>
          <span className="px-2 py-0.5 text-xs font-medium bg-purple-700 text-gray-300 rounded-full">
            {inputValue.length} chars
          </span>
        </div>
        
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="p-1.5 hover:bg-purple-700/50 rounded-lg transition-colors"
          title={isExpanded ? "Minimize" : "Maximize"}
        >
          {isExpanded ? <Minimize2 className="w-4 h-4 text-gray-400" /> : <Maximize2 className="w-4 h-4 text-gray-400" />}
        </button>
      </div>

      {/* Input Area */}
      <div className="relative mt-3">
        <textarea
          rows={isExpanded ? "20" : "5"}
          value={inputValue}
          onChange={(e) => setCustomInput(e.target.value)}
          placeholder="Enter your custom input here..."
          className="w-full px-4 py-3 text-sm rounded-lg bg-gray-800 text-gray-100 border border-gray-700 placeholder-gray-500
            focus:ring-2 focus:ring-purple-500 focus:border-purple-500 resize-none transition-all duration-200"
          spellCheck="false"
        />
        
        {inputValue && (
          <button
            onClick={() => setCustomInput("")}
            className="absolute top-2 right-2 p-1.5 rounded-md hover:bg-gray-700 text-gray-400 hover:text-gray-200 transition-colors duration-200"
            title="Clear input"
          >
            <X size={16} />
          </button>
        )}
      </div>

      {/* Action Buttons */}
      <div className="flex justify-between px-4 py-2 bg-gray-800 rounded-b-xl border-t border-gray-700">
        {/* Paste Button */}
        <button
          onClick={handlePaste}
          className="text-xs text-gray-400 hover:text-gray-200 flex items-center space-x-1.5 transition-colors duration-200"
        >
          <span>Paste from clipboard</span>
        </button>

        {/* Copy Button */}
        <button
          onClick={handleCopy}
          className={`text-xs flex items-center space-x-1.5 transition-all duration-200 ${
            isCopied ? 'text-green-400' : 'text-gray-400 hover:text-gray-200'
          }`}
        >
          {isCopied ? (
            <>
              <Check size={12} />
              <span>Copied!</span>
            </>
          ) : (
            <>
              <Copy size={12} />
              <span>Copy to clipboard</span>
            </>
          )}
        </button>
      </div>

      {isExpanded && (
        <div 
          className="fixed inset-0 bg-black/60 backdrop-blur-sm -z-10"
          onClick={() => setIsExpanded(false)}
        />
      )}
    </div>
  );
};

export default CustomInput;
