import React from 'react';
import { Play, Loader2, Terminal, Sparkles } from 'lucide-react';

const ExecuteButton = ({ code, processing, handleCompile }) => {
  return (
    <button
      onClick={handleCompile}
      disabled={!code || processing}
      className={`
        group relative w-full px-6 py-3 rounded-xl
        font-medium text-sm transition-all duration-300
        focus:outline-none focus:ring-2 focus:ring-offset-2
        disabled:cursor-not-allowed
        ${!code 
          ? 'bg-gray-100 text-gray-400 hover:bg-gray-100/80' 
          : processing 
            ? 'bg-indigo-100 text-indigo-500' 
            : 'bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-white shadow-lg hover:shadow-xl hover:shadow-blue-500/20 active:shadow-md'
        }
      `}
    >
      {/* Hover Border Gradient - Only show when enabled */}
      {code && !processing && (
        <div className="absolute -inset-[1px] bg-gradient-to-r from-blue-600 to-indigo-600 rounded-xl opacity-0 group-hover:opacity-100 blur-sm transition-opacity duration-300 -z-10" />
      )}

      {/* Background Pulse Effect - Only show when processing */}
      {processing && (
        <div className="absolute inset-0 rounded-xl overflow-hidden">
          <div className="relative w-full h-full">
            <div className="absolute inset-0 w-[200%] -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-indigo-400/10 to-transparent" />
          </div>
        </div>
      )}

      {/* Button Content Container */}
      <div className="relative flex items-center justify-center space-x-2 z-10">
        {/* Icon States */}
        {processing ? (
          <Loader2 className="w-4 h-4 animate-spin" />
        ) : (
          <div className="relative w-4 h-4">
            <Terminal className={`
              w-4 h-4 absolute transition-all duration-300 
              ${code ? 'opacity-0 scale-50' : 'opacity-100 scale-100'}
            `} />
            <Play className={`
              w-4 h-4 absolute transition-all duration-300
              ${code ? 'opacity-100 scale-100' : 'opacity-0 scale-50'}
            `} />
          </div>
        )}
        
        {/* Text Content */}
        <span className="relative inline-flex items-center">
          {processing ? "Compiling..." : "Compile and Execute"}
          
          {/* Sparkle Effects - Only show when enabled */}
          {code && !processing && (
            <>
              <Sparkles className="absolute -right-6 -top-5 w-3 h-3 text-blue-200 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <Sparkles className="absolute -left-5 -bottom-4 w-3 h-3 text-blue-200 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-75" />
            </>
          )}
        </span>
      </div>
    </button>
  );
};

export default ExecuteButton;