import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const links = [
    {
      title: "Learning Portal",
      href: "https://testlearning.codingnest.tech",
    },
    {
      title: "Online Classes",
      href: "https://www.classroom.codingnest.tech",
    },
    {
      title: "Mobile App",
      href: "https://play.google.com/store/apps/details?id=com.codingnest.learning",
    }
  ];

  return (
    <header className="relative bg-gradient-to-r from-indigo-900 via-purple-900 to-indigo-900">
      <div className="absolute inset-0 bg-black/20 backdrop-blur-sm"></div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Logo and Brand */}
          <a 
            href="https://codingnest.tech" 
            className="group flex items-center space-x-3 hover:opacity-90 transition-all duration-300"
          >
            <div className="relative">
              <div className="absolute -inset-2 bg-gradient-to-r from-pink-600 to-purple-600 rounded-full blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
              <img 
                src="https://ik.imagekit.io/codingnest/codingnest-bw.png?updatedAt=1712954092111" 
                alt="CodingNest Logo"
                className="relative h-10 w-10 transform group-hover:scale-105 transition duration-300"
              />
            </div>
            <div className="flex flex-col">
              <span className="text-2xl font-bold text-white tracking-tight">
                CodingNest
              </span>
              <span className="text-xs text-purple-200 font-medium tracking-wider">
                ONLINE IDE
              </span>
            </div>
          </a>

          {/* Mobile Menu Button */}
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="lg:hidden relative z-50 text-white hover:text-purple-200 transition-colors"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Desktop Navigation */}
          <nav className="hidden lg:flex items-center space-x-8">
            {links.map((link) => (
              <a
                key={link.title}
                href={link.href}
                className="group relative text-gray-100 hover:text-white text-sm font-medium transition-colors duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="relative z-10">{link.title}</span>
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-gradient-to-r from-purple-400 to-pink-400 group-hover:w-full transition-all duration-300"></span>
              </a>
            ))}
          </nav>
        </div>

        {/* Mobile Navigation */}
        <div 
          className={`lg:hidden fixed inset-0 z-40 bg-gradient-to-b from-indigo-900 to-purple-900 transform transition-transform duration-300 ease-in-out ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <nav className="flex flex-col items-center justify-center h-full space-y-8">
            {links.map((link) => (
              <a
                key={link.title}
                href={link.href}
                className="text-white text-lg font-medium hover:text-purple-200 transform hover:scale-105 transition-all duration-300"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setIsMenuOpen(false)}
              >
                {link.title}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;