import React, { useState } from "react";
import Editor from "@monaco-editor/react";

const CodeEditorWindow = ({ onChange, language, code, theme }) => {
  const [value, setValue] = useState(code || "");
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleEditorChange = (value) => {
    setValue(value);
    onChange("code", value);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div
      className={`${
        isFullScreen ? "fixed inset-0 z-50" : "w-full h-[calc(100vh-8rem)]"
      } overflow-hidden rounded-lg border border-gray-800 bg-[#1e1e1e] transition-all duration-300`}
    >
      {/* Editor Header */}
      <div className="flex items-center justify-between px-4 py-2 bg-gray-900 border-b border-gray-800">
        <div className="flex items-center gap-2">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            className="w-5 h-5 text-gray-400"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <span className="text-sm font-medium text-gray-300">
            {language ? `${language}.${language === "javascript" ? "js" : language === "python" ? "py" : language}` : "code.js"}
          </span>
        </div>

        <div className="flex items-center gap-2">
          <div className="flex items-center px-2 py-1 rounded bg-gray-800">
            <span className="text-xs text-gray-400">{language || "javascript"}</span>
          </div>
          <button
            onClick={() => navigator.clipboard.writeText(value)}
            className="p-1.5 rounded hover:bg-gray-800 text-gray-400 hover:text-gray-300 transition-colors"
            title="Copy code"
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              className="w-4 h-4"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
          </button>
          <button
            onClick={toggleFullScreen}
            className="p-1.5 rounded hover:bg-gray-800 text-gray-400 hover:text-gray-300 transition-colors"
            title={isFullScreen ? "Exit full screen" : "Expand to full screen"}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              className="w-4 h-4"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={isFullScreen ? "M16 8L8 16M8 8l8 8" : "M4 4h6M4 4v6m0-6L9.5 9.5M20 20h-6m6 0v-6m0 6l-5.5-5.5"}
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Editor */}
      <div className={`h-[calc(100%-45px)]`}>
        <Editor
          height="100%"
          width="100%"
          language={language || "javascript"}
          value={value}
          theme={theme || "Brilliance Black"}
          defaultValue="// Write your code here"
          onChange={handleEditorChange}
          options={{
            fontSize: 16,
            fontFamily: "'Fira Code', monospace",
            padding: { top: 16 },
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            folding: true,
            lineNumbers: true,
            roundedSelection: true,
            automaticLayout: true,
            cursorStyle: "line",
            wordWrap: "on",
            tabSize: 2,
            renderWhitespace: "selection",
            smoothScrolling: true,
            contextmenu: true,
            cursorBlinking: "smooth",
            renderLineHighlight: "all"
          }}
        />
      </div>
    </div>
  );
};

export default CodeEditorWindow;
