import React, { useState } from 'react';
import { AlertCircle, CheckCircle2, Clock, XCircle, Maximize2, Minimize2, Copy, Terminal } from 'lucide-react';

const OutputWindow = ({ outputDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  // Status ID constants
  const STATUS = {
    COMPILATION_ERROR: 6,
    SUCCESS: 3,
    TIME_LIMIT_EXCEEDED: 5
  };

  // Safely decode base64 with error handling
  const decodeBase64 = (str) => {
    try {
      return str ? atob(str) : '';
    } catch (e) {
      console.error('Base64 decoding error:', e);
      return 'Error decoding output';
    }
  };

  const getOutputContent = () => {
    const statusId = outputDetails?.status?.id;

    const outputStyles = {
      error: "text-red-400 bg-red-500/10 border-red-500/20",
      success: "text-emerald-400 bg-emerald-500/10 border-emerald-500/20",
      warning: "text-yellow-400 bg-yellow-500/10 border-yellow-500/20"
    };

    const statusConfigs = {
      [STATUS.COMPILATION_ERROR]: {
        text: decodeBase64(outputDetails?.compile_output),
        style: outputStyles.error,
        icon: <AlertCircle className="w-5 h-5 text-red-400" />,
        bgStyle: "from-red-500/20 to-transparent",
        iconBg: "bg-red-500/20"
      },
      [STATUS.SUCCESS]: {
        text: decodeBase64(outputDetails?.stdout),
        style: outputStyles.success,
        icon: <CheckCircle2 className="w-5 h-5 text-emerald-400" />,
        bgStyle: "from-emerald-500/20 to-transparent",
        iconBg: "bg-emerald-500/20"
      },
      [STATUS.TIME_LIMIT_EXCEEDED]: {
        text: 'Time Limit Exceeded',
        style: outputStyles.warning,
        icon: <Clock className="w-5 h-5 text-yellow-400" />,
        bgStyle: "from-yellow-500/20 to-transparent",
        iconBg: "bg-yellow-500/20"
      }
    };

    return statusConfigs[statusId] || {
      text: decodeBase64(outputDetails?.stderr),
      style: outputStyles.error,
      icon: <XCircle className="w-5 h-5 text-red-400" />,
      bgStyle: "from-red-500/20 to-transparent",
      iconBg: "bg-red-500/20"
    };
  };

  const handleCopy = async () => {
    const content = getOutputContent().text;
    await navigator.clipboard.writeText(content);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className={`transition-all duration-300 ease-in-out ${
      isExpanded ? 'fixed inset-4 z-50' : 'w-full'
    }`}>
      <div className={`h-full rounded-xl border border-gray-800 bg-gray-900 shadow-lg 
        backdrop-blur-xl backdrop-saturate-150 overflow-hidden
        transition-all duration-300
        ${isExpanded ? 'scale-100' : 'scale-100'}`}
      >
        {/* Header */}
        <div className="px-4 py-3 border-b border-gray-800 bg-gray-800/50 
          flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Terminal className="w-5 h-5 text-gray-400" />
            <h2 className="text-lg font-semibold text-gray-200">
              Output
            </h2>
          </div>
          <div className="flex items-center space-x-2">
            {outputDetails && (
              <span className="text-sm text-gray-400 bg-gray-800 px-2 py-1 rounded-md">
                {new Date().toLocaleTimeString()}
              </span>
            )}
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="p-1.5 hover:bg-gray-700 rounded-lg transition-colors"
            >
              {isExpanded ? 
                <Minimize2 className="w-4 h-4 text-gray-400" /> : 
                <Maximize2 className="w-4 h-4 text-gray-400" />
              }
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className={`relative ${isExpanded ? 'h-[calc(100%-64px)]' : 'h-56'}`}>
          {outputDetails ? (
            <div className="h-full flex flex-col">
              {/* Status Bar */}
              <div className={`px-4 py-2.5 border-b border-gray-800 flex items-center gap-2 
                bg-gradient-to-r ${getOutputContent().bgStyle}`}
              >
                <div className={`p-1 rounded-lg ${getOutputContent().iconBg}`}>
                  {getOutputContent().icon}
                </div>
                <span className="text-sm font-medium text-gray-300">
                  {outputDetails.status?.description || 'Execution Complete'}
                </span>
                <div className="flex-grow" />
                <button
                  onClick={handleCopy}
                  className={`flex items-center space-x-1 px-2 py-1 rounded-md text-xs
                    transition-all duration-200 ${
                      isCopied 
                        ? 'text-green-400 bg-green-500/20' 
                        : 'text-gray-400 hover:bg-gray-800'
                    }`}
                >
                  {isCopied ? (
                    <CheckCircle2 className="w-4 h-4" />
                  ) : (
                    <Copy className="w-4 h-4" />
                  )}
                </button>
              </div>

              {/* Output Text */}
              <div className={`flex-grow overflow-y-auto p-4 font-mono text-sm
                ${getOutputContent().style} transition-colors duration-300`}
              >
                <pre className="whitespace-pre-wrap">
                  {getOutputContent().text || 'No output'}
                </pre>
              </div>
            </div>
          ) : (
            <div className="h-full flex flex-col items-center justify-center space-y-3 
              text-gray-500 bg-gray-800/30 bg-grid-gray-700/20">
              <Terminal className="w-8 h-8" />
              <span className="text-sm">Run your code to see the output</span>
            </div>
          )}
        </div>
      </div>
      
      {/* Backdrop for expanded state */}
      {isExpanded && (
        <div 
          className="fixed inset-0 bg-black/60 backdrop-blur-sm -z-10"
          onClick={() => setIsExpanded(false)}
        />
      )}
    </div>
  );
};

export default OutputWindow;