import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { Helmet } from "react-helmet";
import { classnames } from "../utils/general";
import { languageOptions } from "../constants/languageOptions";
import { ToastContainer, toast } from "react-toastify";
import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import OutputDetails from "./OutputDetails";
import ThemeDropdown from "./ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import ExecuteButton from "./ExecuteButton";
import Header from "./Header";
import { Share2, Download } from "lucide-react";

const cPlusPlusDefault = `#include<iostream>
using namespace std;
int main(){
  cout<<"Hello from Codingnest.tech";
  return 0;
}`;

const Landing = () => {
  const [code, setCode] = useState(cPlusPlusDefault);
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [theme, setTheme] = useState({
    value: "brilliance-black",
    label: "Brilliance Black",
  });
  const [language, setLanguage] = useState(languageOptions[0]);

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const onSelectChange = (selectedLanguage) => setLanguage(selectedLanguage);

  useEffect(() => {
    if (enterPress && ctrlPress) handleCompile();
  }, [ctrlPress, enterPress]);

  useEffect(() => {
    defineTheme(theme.value);
  }, [theme.value]);

  const onChange = (action, data) => {
    if (action === "code") setCode(data);
  };

  const handleShare = async () => {
    setSharing(true);
    try {
      await navigator.clipboard.writeText(code);
      showSuccessToast("Code copied to clipboard!");
    } catch (err) {
      showErrorToast("Failed to copy code");
    } finally {
      setSharing(false);
    }
  };

  const handleDownload = () => {
    try {
      const element = document.createElement("a");
      const file = new Blob([code], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `code.${language.extension || "txt"}`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      showSuccessToast("Code downloaded successfully!");
    } catch (err) {
      showErrorToast("Failed to download code");
    }
  };

  const handleCompile = () => {
    setProcessing(true);
    const formData = {
      language_id: language.id,
      source_code: btoa(code),
      stdin: btoa(customInput),
    };

    const options = {
      method: "POST",
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
      data: formData,
    };

    axios
      .request(options)
      .then((response) => checkStatus(response.data.token))
      .catch(() => {
        setProcessing(false);
        showErrorToast("Quota exceeded or request error.");
      });
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_RAPID_API_URL}/${token}`,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      const response = await axios.request(options);
      const statusId = response.data.status?.id;

      if (statusId === 1 || statusId === 2) {
        setTimeout(() => checkStatus(token), 2000);
      } else {
        setProcessing(false);
        setOutputDetails(response.data);
        showSuccessToast("Compiled Successfully!");
      }
    } catch {
      setProcessing(false);
      showErrorToast();
    }
  };

  const handleThemeChange = (themeOption) => {
    defineTheme(themeOption.value).then(() => setTheme(themeOption));
  };

  const showSuccessToast = (msg) =>
    toast.success(msg || "Compiled Successfully!");
  const showErrorToast = (msg) => toast.error(msg || "Something went wrong!");

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Online Code Editor - By CodingNest</title>
        <meta
          name="description"
          content="An online code editor where you can write, compile, and share code snippets effortlessly."
        />
        <meta
          name="keywords"
          content="code editor, online compiler, share code, coding platform"
        />
        <meta
          property="og:title"
          content="Online Code Editor - By Codingnest Software Training Institute"
        />
        <meta
          property="og:description"
          content="Write and compile code online with ease. Share your code with others using Codingnest.tech."
        />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/codingnest/assignment.jpg?updatedAt=1721702784944"
        />
        <meta property="og:url" content="https://ide.codingnest.tech" />
      </Helmet>

      <ToastContainer position="top-right" autoClose={2000} />
      <Header />
      <div className="bg-gray-900 py-3 shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Stack vertically on mobile, horizontal on sm+ */}
          <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:justify-between">
            {/* Left side controls */}
            <div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-4">
              {/* Language dropdown stays full width on mobile */}
              <div className="w-full sm:w-auto">
                <LanguagesDropdown onSelectChange={onSelectChange} />
              </div>

              {/* Action buttons stack horizontally even on mobile */}
              <div className="flex space-x-3">
                {/* Share Button */}
                <button
                  onClick={handleShare}
                  disabled={!code || sharing}
                  className={`flex items-center justify-center space-x-2 px-4 py-2 rounded-md border text-sm w-full sm:w-auto font-semibold transition duration-200
      ${
        sharing
          ? "bg-gray-600 text-gray-300 cursor-not-allowed border-gray-500"
          : "bg-[#1f2937] text-green-400 hover:bg-green-500 hover:text-white border-gray-600"
      }`}
                  style={{
                    borderColor: sharing ? "#374151" : "#3b82f6",
                    boxShadow: sharing
                      ? "none"
                      : "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                  }}
                >
                  <Share2 size={18} />
                  <span className="hidden sm:inline">
                    {sharing ? "Copying Code..." : "Share Your Code"}
                  </span>
                  <span className="sm:hidden">{sharing ? "..." : "Share"}</span>
                </button>

                {/* Download Button */}
                <button
                  onClick={handleDownload}
                  disabled={!code}
                  className={`flex items-center justify-center space-x-2 px-4 py-2 rounded-md border text-sm w-full sm:w-auto font-semibold transition duration-200
      ${
        !code
          ? "bg-gray-600 text-gray-300 cursor-not-allowed border-gray-500"
          : "bg-[#1f2937] text-blue-400 hover:bg-blue-500 hover:text-white border-gray-600"
      }`}
                  style={{
                    borderColor: !code ? "#374151" : "#3b82f6",
                    boxShadow: !code
                      ? "none"
                      : "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                  }}
                >
                  <Download size={18} />
                  <span className="hidden sm:inline">Download Code</span>
                  <span className="sm:hidden">DL</span>
                </button>
              </div>
            </div>

            {/* Theme dropdown */}
            <div className="w-full sm:w-auto">
              <ThemeDropdown
                handleThemeChange={handleThemeChange}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col lg:flex-row p-4 space-y-4 lg:space-y-0 lg:space-x-4 lg:overflow-hidden">
        <div className="flex-1 bg-gray-800 p-3 sm:p-4 rounded-lg shadow-lg lg:overflow-hidden h-[400px] lg:h-full">
          <div className="h-full overflow-hidden">
            <CodeEditorWindow
              code={code}
              onChange={onChange}
              language={language.value}
              theme={theme.value}
              className="h-full"
            />
          </div>
        </div>
        <div className="lg:w-1/3 flex flex-col space-y-4 h-full overflow-hidden">
          <OutputWindow outputDetails={outputDetails} className="flex-1" />
          <CustomInput
            customInput={customInput}
            setCustomInput={setCustomInput}
          />
          <ExecuteButton
            code={code}
            processing={processing}
            handleCompile={handleCompile}
          />
          {/* <button
            onClick={handleCompile}
            disabled={!code}
            className={classnames(
              "w-full py-2 rounded-md shadow-lg transition duration-200",
              processing ? "bg-gray-500" : "bg-blue-600 hover:bg-blue-700 text-white"
            )}
          >
            {processing ? "Processing..." : "Compile and Execute"}
          </button> */}
          {outputDetails && <OutputDetails outputDetails={outputDetails} />}
        </div>
      </div>
    </div>
  );
};

export default Landing;
