import React from "react";
import { AiOutlineCheckCircle, AiOutlineClockCircle, AiOutlineDatabase } from "react-icons/ai";

const OutputDetails = ({ outputDetails }) => {
  const getStatusStyles = () => {
    return outputDetails?.status?.description === "Accepted" 
      ? {
          bg: "bg-green-500/10",
          text: "text-white", // Status text color
          icon: "text-green-400",
          border: "border-green-500/20"
        }
      : {
          bg: "bg-red-500/10",
          text: "text-white", // Status text color
          icon: "text-red-400",
          border: "border-red-500/20"
        };
  };

  const statusStyles = getStatusStyles();

  return (
    <div className="mt-4 overflow-hidden">
      <div className="p-4 bg-gray-800/80 backdrop-blur-sm rounded-xl border border-white/5 shadow-lg transition-all duration-300 hover:border-white/10 hover:shadow-xl">
        
        {/* Horizontal Scroll Container */}
        <div className="flex items-center justify-start space-x-4 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800 p-2">
          
          {/* Status */}
          <div className="group flex items-center text-sm space-x-2">
            <div className={`p-2 rounded-lg ${statusStyles.bg} transition-transform duration-300 group-hover:scale-110`}>
              <AiOutlineCheckCircle className={`${statusStyles.icon} w-4 h-4`} />
            </div>
            <p className="flex items-center space-x-2 text-white"> {/* Changed to white */}
              Status:{" "}
              <span className={`
                ml-2 font-semibold px-3 py-1 rounded-lg
                ${statusStyles.bg} ${statusStyles.text}
                border ${statusStyles.border}
                transition-all duration-300
                hover:scale-105
              `}>
                {outputDetails?.status?.description || "Unknown"}
              </span>
            </p>
          </div>

          {/* Execution Time */}
          <div className="group flex items-center text-sm space-x-2">
            <div className="p-2 rounded-lg bg-yellow-500/10 transition-transform duration-300 group-hover:scale-110">
              <AiOutlineClockCircle className="text-yellow-400 w-4 h-4" />
            </div>
            <p className="flex items-center space-x-2 text-white"> {/* Changed to white */}
              Execution Time:{" "}
              <span className="
                ml-2 font-semibold px-3 py-1 rounded-lg
                bg-yellow-500/10 text-white // Status text color
                border border-yellow-500/20
                transition-all duration-300
                hover:scale-105
              ">
                {outputDetails?.time || "N/A"}
              </span>
            </p>
          </div>
        </div>

        {/* Decorative gradient line */}
        <div className="mt-4 h-[1px] bg-gradient-to-r from-transparent via-white/10 to-transparent" />
      </div>
    </div>
  );
};

export default OutputDetails;
